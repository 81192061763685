var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardShareIndicatorStyled', [_c('VPopover', {
    attrs: {
      "placement": 'top-start',
      "popperOptions": _vm.popoverOptions,
      "container": '#app',
      "trigger": 'hover',
      "disabled": _vm.owner !== null,
      "popoverClass": 'dashboardSharePopOver',
      "offset": 10
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverItem', [_c('div', {
          staticClass: "not-owner"
        }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.dashboardSharedMessage')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "shared"
  }, [_c('sharedUserIcon')], 1)]), _vm.owner ? _c('VPopover', {
    staticClass: "owner",
    attrs: {
      "placement": 'top-start',
      "popperOptions": _vm.popoverOptions,
      "container": '#app',
      "trigger": 'hover',
      "popoverClass": 'dashboardSharePopOver',
      "offset": 10
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('PopoverItem', [_c('AvatarBadge', {
          attrs: {
            "size": '30px',
            "fontSize": '14px',
            "userData": _vm.owner,
            "isOwner": true
          }
        }), _c('div', {
          staticClass: "user-data"
        }, [_c('div', [_vm._v(_vm._s(_vm.fullName))]), _c('div', [_vm._v(_vm._s(_vm.userEmail))])]), _c('OwnerBadge')], 1)];
      },
      proxy: true
    }], null, false, 2372703195)
  }, [_c('div', [_c('AvatarBadge', {
    attrs: {
      "size": '30px',
      "fontSize": '14px',
      "userData": _vm.owner,
      "isOwner": true
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }