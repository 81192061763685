<template>
  <TalpaLoaderWrapperStyled v-if="isLoading" />
  <NoDataStyled v-else-if="dashboards.length === 0">
    <i18n path="messages.noDataWithType">
      <template v-slot:type>
        {{ $tc('types.dashboard', 2) }}
      </template>
    </i18n>
  </NoDataStyled>
  <DashboardGridStyled v-else>
    <DashboardGridItemStyled v-for="dashboard in dashboards" :key="dashboard.id">
      <DashboardTitleStyled v-tooltip="tooltip(dashboard.title)">
        <span> {{ dashboard.title }} </span>
      </DashboardTitleStyled>
      <PreviewImageStyled @click="openDashboard(dashboard)">
        <img v-if="getPicture(dashboard)" :src="getPicture(dashboard)" />
        <TalpaCockpitIcon v-else class="brand" width="24" height="24" />
        <SharedIndicator v-if="dashboard.shares && dashboard.shares.length > 0">
          <DashboardShareIndicator :owner="getOwner(dashboard)" />
        </SharedIndicator>
        <ActionPanel v-on:click.stop v-if="dashboardWrite" class="action-panel">
          <VPopover
            :trigger="'manual'"
            :placement="'bottom-start'"
            :open="showPopup === dashboard.id"
            :autoHide="true"
            :popperOptions="popperOptions"
            @hide="hideOptions"
          >
            <MoreHorizontalIcon @click="toggleOptions(dashboard.id)" />
            <template v-slot:popover>
              <DashboardOverviewOptionsMenu
                class="option-menu"
                :isOwner="dashboard.isOwner"
                :isShareEnabled="hasDashboardShareFeature"
                @onAction="performAction($event, dashboard)"
              />
            </template>
          </VPopover>
        </ActionPanel>
      </PreviewImageStyled>
    </DashboardGridItemStyled>
  </DashboardGridStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import chroma from 'chroma-js'

import TalpaCockpitIcon from '@/assets/talpa_cockpit_icon.svg'

import { flexCenter } from '@styles/mixins'

import { dateTimeFull } from '@/utils/filters/time'

import { TalpaLoaderWrapper } from '@common/components'
import DashboardOverviewOptionsMenu from '@/components/Atomic/Molecules/DashboardOverviewOptionsMenu'
import DashboardShareIndicator from '@/components/Atomic/Molecules/DashboardShareIndicator'

import { MoreHorizontalIcon } from 'vue-feather-icons'
import { getUserIdFromToken } from '@common/utils'
import DASHBOARDS_QUERY from '#/graphql/operations/dashboards/dashboards.gql'
import DASHBOARDS_MINIMAL from '#/graphql/operations/dashboards/dashboardsMinimal.gql'
import DELETE_DASHBOARD_LIST_MUTATION from '#/graphql/operations/dashboard/deleteDashboard.gql'
import DELETE_DASHBOARD_SHARE_MUTATION from '#/graphql/operations/dashboard/share/deleteDashboardShare.gql'
import { VPopover, VTooltip } from 'v-tooltip'

const TalpaLoaderWrapperStyled = styled(TalpaLoaderWrapper)`
  background: none;
  height: 250px;
`

const DashboardGridStyled = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 12rem));
  grid-gap: 1rem;
  grid-auto-rows: 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 10rem));
    grid-gap: 1rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 10rem));
    grid-gap: 0.5rem;
  }
`

const NoDataStyled = styled('div')`
  ${flexCenter}
  padding: .5rem;
  height: 250px;
  @media (min-width: 768px) {
    padding: 2rem;
  }
`
const DashboardTitleStyled = styled('div')`
  width: 160px;
  height: 2.8rem;
  text-align: center;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: default;
  .tooltip .tooltip-inner {
    background-color:: ${({ theme }) => theme.colors.primary};
  }

`

const DashboardGridItemStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'title'
    'preview';
  grid-template-rows: 3rem 10rem;
  grid-template-columns: 1fr;
  height: 15rem;
  width: fit-content;
  img {
    filter: saturate(100%);
  }
  .action-panel {
    display: none;
  }
  &:hover {
    .title {
      color: ${p => p.theme.colors.primary};
    }
    img {
      filter: saturate(0%);
    }
    .action-panel {
      display: flex;
    }
  }
  .option-menu {
    position: relative;
    top: -130px;
    left: -10px;
  }
`

const PreviewImageStyled = styled('div')`
  ${flexCenter}
  background: ${p => p.theme.colors.solidBG};
  box-shadow: ${p => p.theme.colors.widgetShadow};
  width: 10rem;
  height: 10rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    width: 9rem;
    height: 9rem;
    object-fit: contain;
    border-radius: 0.5rem;
  }
  > svg {
    transform: scale(2);
    path {
      fill: ${p => chroma(p.theme.colors.primary).alpha(0.1).css()};
      stroke: none;
    }
  }
`

const ActionPanel = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  justify-content: space-between;
  svg {
    padding: 10px 10px;
    width: 20px;
    height: 20px;
    stroke: ${p => chroma(p.theme.colors.primary).alpha(0.5).css()};

    &:hover {
      stroke: ${p => chroma(p.theme.colors.primary).alpha(1).css()};
    }
  }
`
const SharedIndicator = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 10px;
`

export default {
  inject: ['uiSettings', 'permissions', 'theme'],
  directives: {
    tooltip: VTooltip,
  },
  props: {
    dashboards: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    hasDashboardShareFeature: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    TalpaCockpitIcon,
    DashboardGridStyled,
    DashboardGridItemStyled,
    NoDataStyled,
    PreviewImageStyled,
    DashboardTitleStyled,
    ActionPanel,
    MoreHorizontalIcon,
    DashboardOverviewOptionsMenu,
    DashboardShareIndicator,
    SharedIndicator,
    VPopover,
    TalpaLoaderWrapperStyled,
  },
  filters: {
    dateTimeFull,
  },
  data() {
    return {
      showPopup: '',
    }
  },
  computed: {
    dashboardWrite() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write')
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    tooltip(title) {
      return {
        content: title,
        container: '#app',
        classes: 'dashboardTitle',
        placement: 'top',
      }
    },
    getPicture(dashboard) {
      const gridItem = dashboard.gridItems.find(e => e?.widget?.widgetSettings?.__typename === 'DashboardInformationWidgetSettings')
      const partnerName = gridItem?.widget?.widgetSettings?.name
      return !partnerName || partnerName === 'NO_LOGO' ? '' : `/partners/${this.imageGenerator(partnerName)}.png`
    },
    imageGenerator(partnerName) {
      let imageName = ''
      switch (partnerName) {
        case 'DEUTZ':
          imageName = 'DEUTZ'
          break
        case 'EPIROC':
          imageName = this.theme.isDark ? 'EPIROCDark' : 'EPIROCLight'
          break
        case 'SANDVIK':
          imageName = 'SANDVIK'
          break
        case 'SMAG':
          imageName = this.theme.isDark ? 'SMAGDark' : 'SMAGLight'
          break
        case 'GHH':
          imageName = 'GHH'
          break
        case 'BRIDGESTONE':
          imageName = 'BRIDGESTONE'
          break
        case 'TALPA':
          imageName = this.theme.isDark ? 'TalpaDark' : 'TalpaLight'
          break
      }
      return imageName
    },
    openDashboard({ id }) {
      this.$router.push({
        name: 'dashboard',
        params: {
          id,
        },
      })
    },
    editDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'DashboardSettingsOverlay', dashboard)
    },
    confirmDeleteDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Dashboard',
        onConfirm: this.deleteDashboard,
        onConfirmArgs: dashboard,
      })
    },
    confirmUnsubscribeDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Dashboard_share',
        onConfirm: this.unsubscribeDashboard,
        onConfirmArgs: dashboard,
      })
    },
    async unsubscribeDashboard(dashboard) {
      const dashboardShare = get(dashboard, 'shares', []).find(s => s.sharedWithUserId === getUserIdFromToken(this.$keycloak.token))
      const res = await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_SHARE_MUTATION,
        variables: {
          where: {
            id: get(dashboardShare, 'id', ''),
          },
        },
        update: store => {
          const data = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          data.dashboards = data.dashboards.filter(d => d.id !== dashboard.id)

          store.writeQuery({ query: DASHBOARDS_QUERY, data })
          let minimalData = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          minimalData.dashboards = minimalData.dashboards.filter(d => d.id !== dashboard.id)
          store.writeQuery({
            query: DASHBOARDS_MINIMAL,
            data: minimalData,
          })
        },
      })
      return res
    },
    async deleteDashboard({ id }) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_LIST_MUTATION,
        variables: {
          where: {
            id,
          },
        },
        update: (store, { data: { deleteDashboard } }) => {
          let data = store.readQuery({
            query: DASHBOARDS_QUERY,
          })

          data.dashboards = data.dashboards.filter(d => d.id !== deleteDashboard.id)
          store.writeQuery({
            query: DASHBOARDS_QUERY,
            data,
          })

          let minimalData = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          minimalData.dashboards = minimalData.dashboards.filter(d => d.id !== deleteDashboard.id)
          store.writeQuery({
            query: DASHBOARDS_MINIMAL,
            data: minimalData,
          })
        },
      })
      return res
    },
    performAction(action, dashboard) {
      this.showPopup = ''
      switch (action) {
        case 'settings':
          this.editDashboard(dashboard)
          break
        case 'delete':
          if (dashboard.isOwner) {
            this.confirmDeleteDashboard(dashboard)
          } else {
            this.confirmUnsubscribeDashboard(dashboard)
          }
          break
        case 'share':
          this.$root.$emit('activateOverlay', 'DashboardShareOverlay', dashboard)
          break
        default:
          break
      }
    },
    getOwner(dashboard) {
      return dashboard.ownerUserId === this.$keycloak.subject ? null : dashboard.owner
    },
    hideOptions() {
      this.showPopup = ''
    },
    toggleOptions(id) {
      this.showPopup = ''
      this.showPopup = id
    },
  },
}
</script>
